.home-content{
	position: absolute;
	top:0;
	left:0;
	width: 100vw;
	height: 100vh;
	display: flex;
	z-index: 1;
	justify-content: center;
	align-items: center;
    pointer-events: none;
}

.boids-canvas{
	position: absolute;
	top: 0;
	left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.boids-quote{
        z-index: 1;
	font-size: 4em;
	color: white;
}
